import Repository from './Repository';

const resource = "/menus";
export default {
    get() {
        return Repository.get(`${resource}`);
    },
    getMenue(menu) {
        return Repository.get(`${resource}/${menu}`);
    },
}