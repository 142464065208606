<template>
</template>
<script>
import messages from "@/tests/data/messages.json"
export default {
    data() {
        return{
            messages: [],
            message: {}
        }
    },
    mounted() {
        this.message = messages.filter(message=> message.id == this.$route.params.id)[0]
    },
    watch: {
        '$route.params.id':function(){
          this.message = messages.filter(message=> message.id == this.$route.params.id)[0]
        }
    }
}
</script>

<style scoped>

</style>