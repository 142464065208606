<template>
  <div class="" tabindex="0" @keypress="handleKeyPress($event)">
    <div class="block xl:grid grid-cols-2 gap-0">
      <!-- BEGIN: Login Info -->
      <div class=" xl:col-span-1 hidden xl:flex flex-col items-center justify-center z-10">
        <img class="image-bgC object-cover" src="assets/img/background-1.jpg " />
      </div>
      <!-- END: Login Info -->
      <!-- BEGIN: Login Form -->
      <div class="min-h-screen flex flex-col justify-between xl:col-span-1 bg-white login-bg-dark" v-if="login">
          <a href="" class="-intro-x bg-white login-bg-dark self-center mt-10">
            <company-logo :theme="dark" />
          </a>
          <div class="my-10">
              <div class="-intro-x text-center tracking-tighter leading-none font-light text-3xl text-gray-500">
                Welcome to {{ company }}! 
                <br>
                sign in to your account.
              </div>
              <div class="intro-x mt-6 flex flex-col w-3/4 xl:w-1/2 mx-auto">
                <float-label class="mt-8">
                  <input type="text" id="username" @keypress="handleKeyPress($event)" class="intro-x login__input input input--lg border border-gray-300 block w-full" placeholder="Username" v-model="user.username">
                </float-label>  
                <float-label class="mt-8">
                  <input type="password" @keypress="handleKeyPress($event)" class="intro-x login__input input input--lg border border-gray-300 block w-full" placeholder="Password" v-model="user.password">
                </float-label>
                <div class="intro-x text-gray-700 dark:text-gray-200 text-md  mt-2 hover:text-theme-1 text-right w-full">
                  <router-link :to="{name: 'forgot'}">I Forgot my password</router-link>
                </div>
              </div>
              
              <!--login/sign up buttons-->
              <div class="intro-x mt-3 xl:mt-5 text-center " >
                <button class="button button--lg w-32 text-white bg-theme-1 mr-3"  @click.prevent='Login' >Login</button>
                <button class="button button--lg w-32 text-gray-700 dark:text-gray-200 border border-gray-300 mt-3" v-if="allow_signup">
                  <router-link :to="{name: 'signup'}" class="">Sign up</router-link>
                </button>
              </div>
              <!--end of login/sign up buttons -->
          </div>
          <div class="mt-3 mx-auto ">
            <h4 class="text-center text-gray-500">VERFIED BY</h4>
            <div class="">
              <img class="w-6 sm:w-16 inline mx-4" src="/assets/img/logos/iab-logo.png" alt="">
              <img class="w-6 sm:w-14  inline mx-4" src="/assets/img/logos/google-logo.png" alt="">
              <img class="w-12 sm:w-24 inline mx-4" src="/assets/img/logos/forensiq-logo1.png" alt="">
              <img class="w-12 sm:w-24 inline mx-4" src="/assets/img/logos/pubmatic-logo.png" alt="">
              <img class="w-12 sm:w-24 inline mx-4" src="/assets/img/logos/freewheel-logo1.png" alt="">
            </div>
          </div>
          <div class="mx-auto p-5">
            <div class="intro-x flex items-center text-gray-700 mt-4 text-xs sm:text-sm">
              <Terms />
            </div>
          </div>
      </div>
      <!-- END: Login Form -->
      <!-- BEGIN: Register Form -->
      <div class="min-h-screen flex flex-col items-center justify-center py-5 px-5 xl:col-span-1 z-10 bg-white login-bg-dark" v-if="signup">
        <Signup />
      </div>
      <div class="xl:col-span-1 bg-white login-bg-dark" v-if="forgot">
        <ForgotPassword />
      </div>
    <!-- END: Register Form -->
    </div>
  </div>
</template>

<script>
import CompanyLogo from '@/components/core/CompanyLogo'
import Signup from '@/components/account/Signup'
import ForgotPassword from '@/components/account/ForgotPassword'
import Terms from '@/components/core/Terms'

export default {
    name: 'Login',
    components: {
      CompanyLogo,
      Signup,
      ForgotPassword,
      Terms
    },
    props: {
      login: {
        required: false,
        default: false
      },
      signup: {
        required: false,
        default: false
      },
      forgot: {
        required: false,
        default: false
      },
    },
    data() {
      return {
        user: {
          username: '',
          password: '',
        },
        error: null,
        dark: localStorage.getItem("dark-mode")?"":"dark",
      }
  },
  methods: {
    Login: function () {
      this.user.username = this.user.username.toLowerCase()
      this.$store.dispatch('AUTH_REQUEST', this.user).then(result => {
        this.$router.push({name: 'dashboard'})
      }).catch(err => {
        this.$notify({
            group: 'auth',
            type: 'error',
            title: 'Faild to login',
            text: err.response.data.detail
        })
      })
    },
    Signup: function() {      
      if(this.user.password !== this.user.password_confirm) {
          this.errors.push('Passwords not match.')
      }

      if(this.terms == false) {
          this.errors.push('Please make sure to check the terms.')
      }
          
      if(this.errors.length > 0) {
          return
      }

      this.$store.dispatch('SIGNUP', this.user).then(result => {
          this.messages.push(result.data.message)
          this.$store.dispatch('AUTH_REQUEST', this.user).then(result => {})
          setTimeout(function() {
              this.$router.push("/")
          }, 2000)
      }).catch(err => {
          if(typeof err.response.data === 'object') {
              for (var value in err.response.data) {
                  if ( err.response.data.hasOwnProperty(value) ) {
                      var field = value.charAt(0).toUpperCase() + value.slice(1).replace('_', ' ')
                      this.errors.push(field + ' ' + err.response.data[value][0])
                  }
              }
          }
      })
    },
    handleKeyPress(e){
      if (e && e.keyCode == 13){
        this.Login();
      }
    }
  },
  computed: {
    company: function() {
        if(this.$store.getters.whitelist.company_name != null && this.$store.getters.whitelist.company_name != '') {
          return this.$store.getters.whitelist.company_name
        } else {
        return ''
      }
    },
    allow_signup: function() {
      return this.$store.getters.whitelist.allow_signup
    }
  }
}
</script>
<style scoped>
 
.image-bgC{
  height: 150%;
  width: 100%;
}
input[id="username"] {
    text-transform: lowercase;
}
</style>
