import axios from "axios"

//const baseDomain = "/api/v1"
const baseDomain = "https://services.adcrew.co/api/v1/communications"
const baseURL = `${baseDomain}`

const instence = axios.create({
    baseURL,
    withCredentials: true,
    headers: { 
        'Content-Type': 'application/json', 
    }
})
instence.interceptors.request.use(
    (config) => {
      let token = localStorage.getItem('accessToken');
  
      if (token) {
        config.headers['Authorization'] = `Bearer ${ token }`;
      }
  
      return config;
    }, 
  
    (error) => {
      return Promise.reject(error);
    }
  );

// instence.interceptors.request.use(request => {
//     console.log('Starting Request', request)
//     return request
// })
// instence.interceptors.response.use(response => {
//     console.log('Response:', response)
//     return response
// })

export default instence