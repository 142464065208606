import Repository from './Repository';

const resource = "/notifications";
export default {
    all() {
        return Repository.get(`${resource}/`);
    },
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
}