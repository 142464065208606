<template>
<div class="col-span-12">
    <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 lg:col-span-8 mt-8">
            <div class="intro-y flex flex-col items-start sm:flex-row sm:justify-between sm:items-center">
                <h2 class="text-lg font-medium truncate mr-5">
                    Statistics
                </h2>
                <div class="flex flex-row justify-between w-full sm:w-2/3">
                    <div class="my-2 bg-white mr-2 w-1/2 rounded-md shadow-sm">
                        <select id="website" name="website" class="select2">
                            <option selected disabled hidden> -- Websites --</option>
                            <option v-for="website in websites" 
                            :key="website.id" 
                            :value="website.id"
                            :selected="website.id === websites[0].id">{{ website.name }}</option>
                        </select>
                    </div>

                    <div class="my-2 w-1/2 bg-white rounded-md shadow-sm">
                        <select id="group" name="group" class="select2">
                            <option disabled> -- Ad Type -- </option>
                            <option value="all" selected> All </option>
                            <option v-for="group in groups" :key="group.id" :value="group.id" >{{ group.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="intro-y box p-5 mt-2">
                <div class="report-chart">
                    <ChartJS :data="statistics" />
                </div>
            </div>
        </div>
        <!-- END: Sales Report -->
        <div class="col-span-12  lg:col-span-4 mt-8">
            <YesterdayOverall :statistics="yesterday_overall" :isLoading="yesterday_overall_loading" />
        </div>
    </div>
</div>
   
</template>

<script>
import ChartJS from '@/components/analytics/ChartJS'
import YesterdayOverall from '@/components/dashboard/Yesterday_Overall'

import { RepositoryFactory } from '@/services/repositories/PublisherAPI/RepositoryFactory';
const WebsitesRepo = RepositoryFactory.get('websites');
const GroupsRepo = RepositoryFactory.get('groups');
const WebsitesStatistics = RepositoryFactory.get('websites_statistics')
const GroupsStatistics = RepositoryFactory.get('groups_statistics')

export default {
    name: 'Dashboard',
    components: {
        ChartJS,
        YesterdayOverall
    },
    props: {
        yesterday_overall: {
            required: false,
            type: Object | Array
        },
        yesterday_overall_loading: {
            required: true,
            type: Boolean
        },
    },
    data() {
        return {
            websites: [],
            selectedWebsite: "",
            groups: [],
            selectedGroup: "",
            statistics: [],
            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 30,
            isLoading: false
        }
    },
    created() {
        this.loadWebsites()
    },
    mounted() {
        //Initialize Select2 Elements
        $('.select2').select2({
            width: '100%',
        })

        var self = this
        $('#website').on("change", function(e) { 
            self.selectedWebsite = $(this).val()
            self.reloadResults()
        });
        
        $('#group').on("change", function(e) { 
            self.selectedGroup = $(this).val()
            self.reloadResults()
        });
    },
    methods: {
        loadWebsites: function() {
            WebsitesRepo.page(1, 1000).then(result => {
                this.websites = result.data.results
                if(this.websites[0]) {
                    this.selectedWebsite = this.websites[0].id
                    this.loadWebsiteStatistics()
                }
            })
        },
        loadGroups: function() {
            GroupsRepo.get(this.selectedWebsite).then(result => {
                this.groups = result.data.results
            })
        },
        loadWebsiteStatistics: function() {
            this.isLoading = true
            WebsitesStatistics.page(this.selectedWebsite, this.currentPage, this.resultsLimit).then(result => {
                this.totalRecords = result.data.count
                this.statistics = result.data.results
            }).catch(err => {})
            this.isLoading = false
        },
        loadGroupStatistics: function(group_id) {
            this.isLoading = true
            GroupsStatistics.page(group_id, this.currentPage, this.resultsLimit).then(result => {
                this.totalRecords = result.data.count
                this.statistics = result.data.results
            }).catch(err => {})
            this.isLoading = false
        },
        reloadResults: function() {
            if(this.selectedGroup === 'all' || this.selectedGroup === '') {
                this.loadWebsiteStatistics()
            } else {
                this.loadGroupStatistics(this.selectedGroup)
            }
        },
    },
    computed: {
        pageCount: function() {
            return Math.ceil(this.totalRecords / this.resultsLimit)
        },
    },
    watch: {
        "websites": {
            handler: function(id) {
                if(this.websites[0])
                    this.selectedWebsite = this.websites[0].id
                    this.loadWebsiteStatistics()
            },
            deep: true
        },
        "selectedWebsite": {
            handler: function(id) {
                this.loadGroups()
            },
            deep: true
        },
    },
}
</script>
<style>
.report-chart {
    height: 400px;
}
.report-chart:before {
    height: 0;
    width: 0;
}
.report-chart:after {
    height: 0;
    width: 0;
}
.select2-container .select2 {
    width: 240px;
}
</style>