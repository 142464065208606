import Vue from 'vue'
import Vuex from 'vuex'
import VueLocalStorage from 'vue-localstorage';

import AuthStore from './modules/auth'
import MessagingStore from './modules/messaging'
import WhitelabelStore from './modules/whitelabel'

Vue.use(Vuex)
Vue.use(VueLocalStorage);

var auth_header

let PUBLISHER = 'publisher'
let ADMIN = 'admin'
let DEVELOPER = 'developer'

import { RepositoryFactory } from '../services/repositories/AuthAPI/RepositoryFactory'
const AuthRepo = RepositoryFactory.get('auth') 

export default new Vuex.Store({
  modules: {
    Auth: AuthStore,
    Messaging: MessagingStore,
    Whitelabel: WhitelabelStore
  }
})
