<template>
<div class=" grid grid-cols-12 ">
    <div class="intro-y col-span-12 mt-5">
        <div class="intro-y box">
            <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200">
                <h3 class="font-medium text-base mr-auto"> Reports </h3>
            </div>

            <div class="intro-y p-5 grid grid-cols-12">
                <div class="flex flex-col m-2 col-span-12 lg:col-span-2 ">
                    <label class="font-medium text-base">Websites</label>
                    <div class="mr-2 mt-2">
                        <select id="website" name="website" class="select2 border">
                            <option selected disabled hidden> -- Websites --</option>
                            <option v-for="website in websites" :key="website.id" :value="website.id" >{{ website.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="flex flex-col m-2 col-span-12 lg:col-span-2">
                    <label class="font-medium text-base">Ad Type</label>
                    <div class="mr-2 mt-2">
                        <select id="group" name="group" class="select2  border" :disabled="!selectedWebsite">
                            <option selected value="all"> All </option>
                            <option v-for="group in groups" :key="group.id" :value="group.id" >{{ group.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="flex flex-col m-2 col-span-12 lg:col-span-2">
                    <label class="font-medium text-base">Date</label>
                    <div class="relative mr-2 mt-2">
                        <div class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 date-bg-dark"> <i data-feather="calendar" class="w-4 h-4"></i> </div>
                        <input type="text" id="daterange" class="daterange input w-full border pl-12">
                    </div>
                </div>
                <div class="flex flex-col m-2 col-span-12 lg:col-span-2">
                    <label class="font-medium text-base">Limit</label>
                    <select class="input mr-2 mt-2 border" style="width: 100%;" v-model="limit">
                        <option value="10">10</option>
                        <option selected value="15">15</option>
                        <option value="30">30</option>
                        <option value="60">60</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div class="flex flex-col m-2 col-span-12 lg:col-span-2">
                    <label class="font-medium text-base">Order</label>
                    <select class="input mr-2 mt-2 border" style="width: 100%;" v-model="order">
                        <option selected="selected" value="-date">Newest</option>
                        <option value="date">Older</option>
                    </select>
                </div>
                <div class="m-2 mt-4 lg:mt-0 lg:relative col-span-12 lg:col-span-2">
                    <a href="#" class="lg:absolute lg:bottom-0 lg:left-0 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-16 rounded mt-8"
                    @click.prevent="loadResult"> <fa-icon :icon="['fa', 'search']" /> Report </a>
                </div>

            </div>
        </div>
    </div>
    <Statistics
        :website="selectedWebsite"
        :group="selectedGroup"
        :start_date="start_date" :end_date="end_date"
        :order="order" :limit="limit"
        :search="search"
     />
</div>
</template>

<script>
import Statistics from '@/components/analytics/Statistics'
import { RepositoryFactory } from '@/services/repositories/PublisherAPI/RepositoryFactory';
const WebsitesRepository = RepositoryFactory.get('websites');
const GroupsRepository = RepositoryFactory.get('groups');

export default {
    components: {
        Statistics
    },
    data() {
        return {
            websites: [],
            selectedWebsite: 0,
            groups: [],
            selectedGroup: 0,
            start_date: "",
            end_date: "",
            date: "",
            order: "-date",
            limit: 30,
            search: 0
        }
    },
    created() {
        this.loadWebsites()
    },
    mounted() {
        this.$feather.replace()
        
        //Initialize Select2 Elements
        $('.select2').select2({width: '100%'})
        
        //Date range as a button
        var self = this
        function daterange(start, end) {
            self.start_date = start.format('YYYY-MM-DD')
            self.end_date = end.format('YYYY-MM-DD')
        }

        var startDate = this.$moment().subtract(29, 'days')
        var endDate = this.$moment()
        $('#daterange').daterangepicker({
                ranges   : {
                    'Today'       : [this.$moment(), this.$moment()],
                    'Yesterday'   : [this.$moment().subtract(1, 'days'), this.$moment().subtract(1, 'days')],
                    'Last 7 Days' : [this.$moment().subtract(6, 'days'), this.$moment()],
                    'Last 30 Days': [this.$moment().subtract(29, 'days'), this.$moment()],
                    'This Month'  : [this.$moment().startOf('month'), this.$moment().endOf('month')],
                    'Last Month'  : [this.$moment().subtract(1, 'month').startOf('month'), this.$moment().subtract(1, 'month').endOf('month')]
                },
                startDate: startDate,
                endDate  : endDate
            },
            daterange
        )
        daterange(startDate, endDate)
        
        var self = this
        $('#website').on("change", function(e) { 
            self.selectedWebsite = $(this).val()
            self.selectedGroup = 'all'
        });
        
        $('#group').on("change", function(e) { 
            self.selectedGroup = $(this).val()
        });
    },
    methods: {
        loadWebsites: function() {
            WebsitesRepository.page(1, 1000).then(result => {
                this.websites = result.data.results
            })
        },
        loadGroups: function() {
            GroupsRepository.get(this.selectedWebsite).then(result => {
                this.groups = result.data.results
            })
        },
        loadResult: function() {
            this.search += 1
        },
    },
    watch: {
        "selectedWebsite": function(id) {
            this.loadGroups()
        },
    },
}
</script>