import Repository from './Repository';

const resource = "/placements_groups";
export default {
    all() {
        return Repository.get(`${resource}/?limit=1000`);
    },
    ids(ids) {
        return Repository.get(`${resource}/?ids=${ids}&limit=1000`);
    },
    get(website_id) {
        return Repository.get(`${resource}/?website=${website_id}`);
    },
    page(page, limit=10) {
        var offset = (page - 1) * limit
        return Repository.get(`${resource}/?limit=${limit}&offset=${offset}`);
    }
}