<template>
    <canvas id="report-line-chart" height="400" class="mt-6"></canvas>
</template>

<script>
import statisticsToGraphData from './data'
export default {
    name: 'ChartJS',
    props: {
        data: {
            type: Array,
            required: true
        }
    },
    created() {
    },
    mounted: function() {
        this.init()
        //Initialize Select2 Elements
        $('.select2').select2()
    },
    methods: {
        init: function() {
            let ctx = document.getElementById('report-line-chart').getContext('2d') 
            this.currenGraph(ctx)
        },
        currenGraph: function(element) {
            let last14DaysStatistics = statisticsToGraphData(this.data, true, false, true)
            var labels = last14DaysStatistics.date
            var dataSet = [{
                yAxisID: 'y-axis-1',
                label: "Impressions",
                borderColor: '#2caece',
                backgroundColor: 'RGBA(38, 53, 86, 0.22)',
                fill: true,
                data: last14DaysStatistics.impressions
            }, {
                yAxisID: 'y-axis-2',
                label: "Revenue",
                borderColor: '#21bb9b',
                backgroundColor: 'RGBA(33,187,155,0.22)',
                fill: true,
                data: last14DaysStatistics.revenue
            }];
            var options = {
                responsive: true,
                maintainAspectRatio: false,
                hoverMode: 'index',
                stacked: true,
                title:{
                    display: false,
                    text:'Last 14 Days'
                },
                scales: {
                    yAxes: [{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        title: "Impressions",
                        position: "left",
                        id: "y-axis-1",
                        ticks: {
                            beginAtZero: true,
                        }
                    }, {
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        title: "Revenue",
                        position: "right",
                        id: "y-axis-2",
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            beginAtZero: true,
                        }
                    }],
                    xAxes: [{
                        display: true,
                        id: "x-axis",
                        type: 'time',
                        time: {
                            unit: 'day'
                        },
                        gridLines: {
                            display: true
                        },
                    }],
                }
            };

            new Chart.Line(element, {
                data: {
                    labels: labels,
                    datasets: dataSet
                },
                options: options
            })
        }
    },
    watch: {
        data: {
            handler: function(data) {
                this.init()
            },
            deep: true
        }
    }
}
</script>

<style>

</style>