<template>
    <div class=" min-h-screen flex flex-col items-center py-10" v-if="isNotAuthenticated">
        <div class="-intro-x pt-5">
            <company-logo position="sidebar" />
        </div>
        <div class="box p-6 mt-12 w-11/12 sm:w-3/4 xl:w-1/3" >
        <h2 class="intro-x font-bold text-xl py-2 text-center xl:text-left">Account verification.</h2>
            <div class="intro-y rounded-md flex items-center px-5 py-4 mb-2 bg-theme-31 text-theme-6 mb-5" v-if="!verified">
                <fa-icon :icon="['fas', 'exclamation-triangle']" class="w-6 h-6 mr-2" /> This url is not valid or has expired!
            </div>
            <div class="intro-y rounded-md flex items-center px-5 py-4 mb-2 bg-theme-18 text-theme-9 mb-5" v-if="verified">
                <fa-icon :icon="['fas', 'check']" class="w-6 h-6 mr-2" /> Your account has been verified.
            </div>
            <router-link :to="{name: 'login'}" class="button button--md w-32 text-gray-700 border border-gray-300 dark:text-gray-200 dark:border-white">Return to login</router-link>
        </div>
    </div>
</template>
<script>
import CompanyLogo from '@/components/core/CompanyLogo'

export default {
    components: {
      CompanyLogo,
    },
    data() {
        return {
            errors: [],
            messages: [],
            verified: false,
            user: {
                key: null,
                type: 'account'
            }
        }
    }, 
    created() {
        this.verifiedKey()
    },
    methods: {
        verifiedKey: function() {
            this.user.key = this.$route.params.key
            this.$store.dispatch('VERIFY', this.user).then(result => {
                this.verified = true
            })
            .catch(err => {
                this.verified = false
            })
        },
    },
    computed: {
        isNotAuthenticated: function() {
            if(!this.$store.getters.isAuthenticated) {
                return true
            }
            this.$router.push('/')
        },
    }
}
</script>
<style scoped>
    
</style>