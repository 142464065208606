<template>
<div class="intro-y box lg:mt-5">
    <div v-if="editMood">
        <div class="flex items-center border-b p-5  border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-lg mr-auto">
                Profile Information
            </h2>
            <div @click.prevent="editMood = false" class="outline-none text-theme-3 text-lg py-5 cursor-pointer">
                <fa-icon :icon="['fas' , 'arrow-left']" /> Back
            </div>
        </div>
        <div class="p-5">
            <div class="px-0 sm:px-10">
                <div class="intro-x flex flex-col sm:flex-row items-center mt-5">
                <label for="inputName" class="w-full sm:w-40 font-medium text-base  sm:mr-5"><fa-icon :icon="['fas' , 'mobile']" /> Phone Number</label>
                <input type="text" class="input w-full border mt-2 flex-1" v-model="profile.phone_number">
            </div>
            <div class="intro-x flex flex-col sm:flex-row items-center mt-5">
                <label for="inputEmail" class="w-full sm:w-40 font-medium text-base  sm:mr-5"><fa-icon :icon="['fas' , 'address-book']" /> Address</label>
                <textarea class="input w-full border mt-2 flex-1" v-model="profile.address"></textarea>
            </div>
            <div class="intro-x flex flex-col sm:flex-row items-center mt-5">
                <label for="inputName2" class="w-full sm:w-40 font-medium text-base  sm:mr-5"><fa-icon :icon="['fas' , 'city']" /> City</label>
                <input type="text" class="input w-full border mt-2 flex-1" v-model="profile.city">
            </div>
            <div class="intro-x flex flex-col sm:flex-row items-center mt-5">
                <label for="inputExperience" class="w-full sm:w-40 font-medium text-base  sm:mr-5"><fa-icon :icon="['fas' , 'flag']" /> State</label>
                <input type="text" class="input w-full border mt-2 flex-1" v-model="profile.state">
            </div>
            <div class="intro-x flex flex-col sm:flex-row items-center mt-5">
                <label for="inputSkills" class="w-full sm:w-40 font-medium text-base  sm:mr-5"><fa-icon :icon="['fas' , 'flag']" /> Country</label>
                <input type="text" class="input w-full border mt-2 flex-1" v-model="profile.country">
            </div>
            </div>
            <div class="border-t mt-12 dark:border-dark-5 pt-5">
                <button @click.prevent="saveProfileInfo()" type="button" class="button bg-theme-1 text-white py-2 px-8">Update</button>
            </div>
            <errors :errors="errors" />
            <messages :messages="messages" />
        </div>
    </div>

    <div  v-else >
        <div class="flex items-center border-b p-5  border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-lg mr-auto">
                Profile Information
            </h2>
            <div @click.prevent="editMood = true" class="outline-none text-theme-6 text-lg py-5 cursor-pointer">
                <fa-icon :icon="['fas' , 'user-edit']" /> Edit Profile
            </div>
        </div>
        
        <div class="p-5 ">
            <div class="mx-auto w-full lg:w-11/12 px-6" >
                <div class="intro-x border-b dark:border-dark-5 mt-5" >
                    <div  class="w-32 font-medium text-base inline"> <fa-icon :icon="['fas' , 'mobile']" /> Phone Number:</div>
                    <div  class="outline-none text-lg py-5" v-if="profile.phone_number">{{profile.phone_number  }}</div>
                    <div class="outline-none text-theme-6 text-lg py-5 cursor-pointer" @click.prevent="editMood = true" v-else><fa-icon :icon="['fas' , 'plus-circle']" /> Add Phone Number</div>
                </div>
                <div class="intro-x border-b dark:border-dark-5 mt-5">
                    <div  class="w-32 font-medium text-base inline"><fa-icon :icon="['fas' , 'address-book']" /> Address:</div>
                    <div class="outline-none text-lg py-5" v-if="profile.address" >{{profile.address   }}</div>
                    <div class="outline-none text-theme-6 text-lg py-5 cursor-pointer" @click.prevent="editMood = true" v-else><fa-icon :icon="['fas' , 'plus-circle']" /> Add Address</div>
                </div>
                <div class="intro-x border-b dark:border-dark-5 mt-5">
                    <div  class="w-32 font-medium text-base inline"><fa-icon :icon="['fas' , 'city']" /> City:</div>
                    <div class="outline-none text-lg py-5" v-if="profile.city">{{profile.city  }}</div>
                    <div class="outline-none text-theme-6 text-lg py-5 cursor-pointer" @click.prevent="editMood = true" v-else><fa-icon :icon="['fas' , 'plus-circle']" /> Add City</div>
                </div>
                <div class="intro-x border-b dark:border-dark-5 mt-5">
                    <div  class="w-32 font-medium text-base inline"><fa-icon :icon="['fas' , 'flag']" />  State:</div>
                    <div class="outline-none text-lg py-5" v-if="profile.state" >{{profile.state  }}</div>
                    <div class="outline-none text-theme-6 text-lg py-5 cursor-pointer" @click.prevent="editMood = true" v-else><fa-icon :icon="['fas' , 'plus-circle']" /> Add State</div>
                </div>
                <div class="intro-x  mt-5">
                    <div  class="w-32 font-medium text-base inline"><fa-icon :icon="['fas' , 'flag']" /> Country:</div>
                    <div class="outline-none text-lg py-5" v-if="profile.country">{{profile.country }}</div>
                    <div class="outline-none text-theme-6 text-lg py-5 cursor-pointer" @click.prevent="editMood = true" v-else><fa-icon :icon="['fas' , 'plus-circle']" /> Add Country</div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/AuthAPI/RepositoryFactory';
const ProfileRepository = RepositoryFactory.get('profile');

export default {
    name: 'ProfileInfo',
    props: {
    },
    data() {
        return {
            errors: [],
            messages: [],
            profile: {},
            editMood : false 
        }
    },
    created() {
        this.getProfileInfo()
    },
    methods: {
        saveProfileInfo: function() {
            this.errors = []
            this.messages = []

            ProfileRepository.update(this.user.id, this.profile).then(result => {
                this.messages.push("Successfuly saved.")
                setTimeout(() =>{
                    this.editMood = false 
                    this.messages.pop("Successfuly saved") },1500)     
            }).catch(err => {
                this.errors.push("Failed to save, please try agian.")
            })
            
        },
        getProfileInfo: async function() {
            const { data } = await ProfileRepository.get(this.user.id)
            this.profile = data
        },
    },
    computed: {
      user: function() {
        return this.$store.getters.user
      },
    }
}
</script>