import Repository from './Repository';

const resource = "/websites";
export default {
    get() {
        return Repository.get(`${resource}/`);
    },
    all() {
        return Repository.get(`${resource}/`);
    },
    page(page, limit=10, search='') {
        var offset = (page - 1) * limit
        if(search) { search = `&search=${search}` }
        return Repository.get(`${resource}/?limit=${limit}&offset=${offset}${search}`);
    },
    filter(ids) {
        return Repository.get(`${resource}/?ids=${ids}`);
    },
    ids(ids) {
        return Repository.get(`${resource}/?ids=${ids}&limit=1000`);
    },
}