<template>
    <div class="col-span-12 intro-y mt-8">
        <div class="intro-y box">
            <div class="flex flex-row justify-between p-5 border-b border-gray-200">
                <h3 class="font-medium text-base mr-auto">Results</h3>

                <div class="intro-x relative">
                    <button class="btn btn-tool" @click.prevent="loadWebsiteStatistics()">
                        <fa-icon :icon="['fas', 'sync']" :class="{'fa-spin': isLoading}"  aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
        <div class="truncate">
            <div class="intro-y overflow-auto xl:overflow-hidden mt-8 sm:mt-0" >
                <table class="table table-report ">
                    <thead>
                    <tr>
                        <th class="whitespace-no-wrap">Date</th>
                        <th class="text-center whitespace-no-wrap" v-for="metric in metrics" :key="metric"> {{ metric | capitalizeAndReplace }} </th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-if="isLoading">
                            <td :colspan="metrics.length+2" class="text-center">
                                <point-spinner />
                            </td>
                        </tr>
                        <tr v-if="statistics.length <= 0" class="intro-x">
                            <td :colspan="metrics.length+2" class="text-center">
                                <span v-if="!isLoading">No statistics available</span>
                                <span v-if="isLoading"><i class="fa fa-spinner fa-3x fa-pulse"></i></span>
                            </td>
                        </tr>
                        <tr v-for="statistic in statistics" :key="statistic.id" class="intro-x">
                            <td class="w-40">{{ statistic.date }}</td>
                            <td class="w-40 text-center" v-for="metric in metrics" :key="metric"> {{ signs[metric] }}{{ statistic[metric] | withComma }} </td>
                        </tr>
                    </tbody>
                    <tfoot v-if="statistics.length > 0">
                        <tr>
                            <td>Total</td>
                            <td class="w-40 text-center" v-for="metric in metrics" :key="metric"> 
                                <span v-if="statisticsSum[metric] >= 0">{{ signs[metric] }}</span>{{ statisticsSum[metric] | withComma}}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>    
        </div>
        <!-- pagination -->
        <div class="intro-y flex flex-wrap sm:flex-row sm:flex-no-wrap items-center mt-3">
            <ul class="pagination">
                <li>
                    <a class="pagination__link pagination__link--active" href="#" @click.prevent="previousPage">Previous</a>
                </li>
                <li>
                    <a class="pagination__link">{{ currentPage }} / {{ pageCount }}</a>
                </li>
                <li>
                    <a class="pagination__link pagination__link--active" href="#" @click.prevent="nextPage">Next</a>
                </li>
            </ul>
        </div>
        <!-- pagination -->
    </div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/PublisherAPI/RepositoryFactory'
const SystemRepo = RepositoryFactory.get('system')
const WebsitesStatistics = RepositoryFactory.get('websites_statistics')
const GroupsStatistics = RepositoryFactory.get('groups_statistics')

import '../../../node_modules/daterangepicker/daterangepicker'
import '../../../node_modules/select2/dist/js/select2.min.js'

export default {
    props: {
        search: {
            type: Number
        },
        website: {
            type: Number | String,
            required: true
        },
        group: {
            type: Number | String,
            required: true
        },
        start_date: {
            type: String,
            required: true
        },
        end_date: {
            type: String,
            required: true
        },
        order: {
            type: String,
            required: false,
            default: "-date"
        },
        limit: {
            type: Number | String,
            required: false,
            default: 15
        }
    },
    data() {
        return {
            metrics: [],
            signs: {},
            statistics: [],
            statisticsSum: {},
            currentPage: 1,
            totalRecords: 0,
            isLoading: false
        }
    },
    created() {
        this.loadSystemMetrics()
    },
    methods: {
        loadSystemMetrics: function() {
            SystemRepo.metrics().then(result => {
                this.metrics = result.data.display
                this.signs = result.data.signs
            }).catch(err => {

            })
        },
        loadWebsiteStatistics: function() {
            this.isLoading = true
            WebsitesStatistics.page(this.website, this.currentPage, 
                this.limit, this.start_date, 
                this.end_date, this.order).then(result => {

                this.totalRecords = result.data.count
                this.statistics = result.data.results
                this.statisticsSum = result.data.statistics_sum

                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
            })
        },
        loadGroupStatistics: function() {
            this.isLoading = true
            GroupsStatistics.page(this.group, this.currentPage, 
                this.limit, this.start_date, 
                this.end_date, this.order).then(result => {

                this.totalRecords = result.data.count
                this.statistics = result.data.results
                this.statisticsSum = result.data.statistics_sum

                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
            })
        },
        reloadResults: function() {
            this.statistics = []
            if(this.group === 'all') {
                this.loadWebsiteStatistics()
            } else {
                this.loadGroupStatistics()
            }
        },
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
                this.reloadResults()
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
                this.reloadResults()
            }
        },
    },
    computed: {
        pageCount: function() {
            return Math.ceil(this.totalRecords / this.limit)
        },
    },
    watch: {
        'search': function(search) {
            if(search) {
                this.reloadResults()
            }
        }
    }
}
</script>
<style scoped>
@import '../../../node_modules/select2/dist/css/select2.min.css';
.x-large {
    font-size: x-large;
}
</style>