<template>
<div class="col-span-12 lg:col-span-6 ">
    <div class="intro-y flex items-center h-10">
        <h2 class="text-lg font-medium truncate mr-5">
            WEBSITES YESTERDAY
        </h2>
    </div>
    <div class="mt-5" v-if="isLoading"><point-spinner /></div>
    <div class="mt-5">
        <div class="intro-y" v-for="(website, index) in websites_list"
         :key="index"
         :class="{displayNone:index>=listCounter}"
>
            <div class="box px-4 py-4 mb-3 flex items-center zoom-in">
                <div class="ml-4 mr-auto">
                    <div class="font-medium">{{ website.name }}</div>
                    <div class="text-gray-600 text-xs">
                        <span v-if="WebsiteStat(website.id).revenue">${{ WebsiteStat(website.id).revenue }}</span>
                        <span v-if="!WebsiteStat(website.id).revenue">$0</span>
                    </div>
                </div>
                <div v-if="WebsiteStat(website.id).revenue_diff == 0"
                    class="py-1 px-2 rounded-full text-xs text-white cursor-pointer font-medium WY-num-dark" :class="{'bg-theme-5': WebsiteStat(website.id).revenue_diff == 0}">
                    {{ WebsiteStat(website.id).revenue_diff }}%
                </div>
                <div v-if="WebsiteStat(website.id).revenue_diff > 0" 
                    class="py-1 px-2 rounded-full text-xs text-white cursor-pointer font-medium" :class="{'bg-theme-9': WebsiteStat(website.id).revenue_diff > 0}">
                    {{ WebsiteStat(website.id).revenue_diff }}%
                    <fa-icon :icon="['fas', 'chevron-up']" />
                </div>
                <div v-if="WebsiteStat(website.id).revenue_diff < 0" 
                    class="py-1 px-2 rounded-full text-xs text-white cursor-pointer font-medium" :class="{'bg-theme-6': WebsiteStat(website.id).revenue_diff < 0}">
                    {{ WebsiteStat(website.id).revenue_diff }}%
                    <fa-icon :icon="['fas', 'chevron-down']" />
                </div>
            </div>
        </div>
        <a href="" v-if="viewMoreBtn" ref="viewMoreBtn"  @click.prevent="viewMore()" class="intro-y w-full block text-center rounded-md py-4 border border-dotted border-theme-15 text-theme-16">{{message ||'View More'}}</a>
        <a href="" v-if="!viewMoreBtn"  @click.prevent="showLess()" class="intro-y w-full block text-center rounded-md py-4 border border-dotted border-theme-15 text-theme-16">Show Less</a>    
    </div>
</div> 
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/PublisherAPI/RepositoryFactory'
const WebsitesRepo = RepositoryFactory.get('websites')
const WebsiteStatisticsRepo = RepositoryFactory.get('websites_statistics')

export default {
    props: {
        websites: {
            required: false,
            type: Object | Array
        },
        isLoading: {
            required: true,
            type: Boolean
        },
    },
    data() {
        return {
            websites_ids: [],
            websites_list: [],
            yesterday_lifetime: [],
            viewMoreBtn:true,
            listCounter:4,
            message:""
        }
    },
    created() {
    },
    methods: {
        filterWebsitesList: function() {
            let list = []
            this.websites.forEach(function(element) {
                list.push(element.website_id)
            })
            this.websites_ids = list
            this.loadWebsites()
        },
        loadWebsites: function() {
            WebsitesRepo.ids(this.websites_ids).then(result => {
                this.websites_list = result.data.results
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
            })
        },
        WebsiteStat: function(website_id) {
            var website = this.websites.filter(element => { return element.website_id == website_id })[0]
            if(website)
                return website
            else
                return {
                    website_id: website_id,
                    revenue: 0,
                    revenue_diff: 0
                }
        },
        Website: function(website_id) {
            var website = this.websites_list.filter(element => { return element.id == website_id })[0]
            if(website)
                return website
        },
        WebsiteName: function(website_id) {
            var website = this.websites_list.filter(element => { return element.id == website_id })[0]
            if(website)
                return website.name
        },
        viewMore:function(){
            if (this.websites_list.length > 4){
                this.listCounter=this.listCounter +4;    
                if(this.listCounter>=this.websites_list.length)
                    this.viewMoreBtn =false
            }
            else{
                this.message="No More Views" 
                this.$refs.viewMoreBtn.style.cursor = "not-allowed"
            }    
        },
        showLess:function(){
            this.listCounter=4;
            this.viewMoreBtn=true;
        },
    },
    watch: {
        websites: {
            handler: function() {
                this.filterWebsitesList()
            },
            deep: true
        }
    }
}
</script>
<style scoped>
.displayNone{
    display:none;
    }
</style>
