import TagsRepo from './TagsRepo'

import WalletsRepo from './Wallets/WalletsRepo'
import InvoicesRepo from './Wallets/InvoicesRepo'
import AccountsRepo from './Wallets/AccountsRepo'

import WebsitesRepo from './WebsitesRepo'
import GroupsRepo from './GroupsRepo'
import AdsRepo from './AdsRepo'
import AnalyticsRepo from './AnalyticsRepo'
import GroupsTypesRepo from './GroupsTypesRepo'
import SystemRepo from './SystemRepo'
import WebsitesStatisticsRepo from './WebsitesStatisticsRepo'
import GroupsStatisticsRepo from './GroupsStatisticsRepo'

import WhitelistRepo from './WhitelistRepo'
import BankRepo from '../AuthAPI/BankRepo'

const repositories = {
    groups: GroupsRepo,
    websites: WebsitesRepo,
    tags: TagsRepo,
    ads: AdsRepo,
    analytics: AnalyticsRepo,
    system: SystemRepo,
    groups_types: GroupsTypesRepo,
    websites_statistics: WebsitesStatisticsRepo,
    groups_statistics: GroupsStatisticsRepo,

    whitelist: WhitelistRepo,

    wallets: WalletsRepo,
    invoices: InvoicesRepo,
    account_wallets: AccountsRepo,
    bank: BankRepo,
}

export const RepositoryFactory = {
    get: name => repositories[name]
};