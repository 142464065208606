<template>
<div>
    <router-view></router-view>
    <notifications group="ops" position="bottom right" width="500" />
    <notifications group="notifications" position="top right" />
    <notifications group="auth" position="top center" />
</div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/PublisherAPI/RepositoryFactory'
const WhitelistRepo = RepositoryFactory.get('whitelist')

export default {  
  name: "App",
  components: {
  },
  mounted() {
    this.$feather.replace({
        'stroke-width': 1.5
    })
    this.Whitelist()
  },
  methods: {
    Whitelist: function() {
      var data = {
        domain: window.location.origin
      }
      WhitelistRepo.verify(data).then(result => {
        this.$store.commit('whitelist', result.data)
        this.$store.commit('response', true)
      }).catch(err => {
        this.$store.commit('response', false)
      })
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
}
</script>
<style lang="scss">
.vue-notification {
  padding: 20px;
  margin: 0 10px 10px;
  font-size: 18px;

  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0,0,0,.125);

  box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
  margin-bottom: 1rem;

  &.warn {
    background: #FFCE54;
    border-left-color: #f48a06;
  }

  &.error {
    background: #FC6E51;
    border-left-color: #B82E24;
  }

  &.success {
    background: #A0D468;
    border-left-color: #42A85F;
  }
}
</style>
<style>
input[id="username"] {
    text-transform: lowercase;
}
</style>