<template>
    <div class=" min-h-screen flex flex-col items-center py-10" v-if="isNotAuthenticated">
        <div class="-intro-x pt-5">
            <company-logo position="sidebar" />
        </div>
        <div class="box p-6 mt-12 w-11/12 sm:w-3/4 xl:w-1/3 " >
            <div class="" v-if="!verified">
                <div class="intro-x my-8 text-lg text-theme-6 dark:text-theme-2"> This url is not valid or has expired! </div>
                <div class="dropdown-divider"></div>
                <router-link :to="{name: 'login'}" class="button button--md w-32 text-gray-700 border border-gray-300 dark:text-gray-200 dark:border-white">Return to login</router-link>
            </div>
            <div class="intro-x" v-if="verified">
                <h2 class="intro-x font-bold text-xl py-2 text-center xl:text-left">Set your new password</h2>
                <float-label>
                    <input type="password" v-model="user.password" class="intro-x login__input input input--lg border border-gray-300 block w-full mt-4" placeholder="Password" required>
                </float-label>
                <float-label>
                    <input type="password" v-model="user.confirmed_password" class="intro-x login__input input input--lg border border-gray-300 block w-full mt-4" placeholder="Password Confirmation" required>
                </float-label>

                <errors :errors="errors" />
                <messages :messages="messages" />

                <div class="intro-x flex flex-row my-6">
                    <router-link :to="{name: 'login'}" class="button button--md w-32 text-gray-700 border border-gray-300 mr-2 dark:text-gray-200 dark:border-white">Return to login</router-link>
                    <button @click.prevent="reset()" class="button button--md w-32 text-white bg-theme-1 mr-2">Reset</button>

                </div>
                <div class="dropdown-divider"></div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            errors: [],
            messages: [],
            verified: false,
            user: {
                key: null,
                password: null,
                confirmed_password: null,
            }
        }
    },
    created() {
        this.verifiedKey()
    },
    methods: {
        verifiedKey: function() {
            this.user.key = this.$route.params.key
            this.$store.dispatch('VERIFY', this.user).then(result => {
                this.verified = true
            })
            .catch(err => {
                this.verified = false
            })
        },

        reset: function() {
            this.errors = []
            this.messages = []

            this.$store.dispatch('RESET', this.user).then(result => {
                this.messages.push(result.data.message)
                setTimeout(function() {
                    this.$router.push({name: 'login'})
                }, 3000)
            }).catch(err => {
                this.errors.push(err.response.data.message)
            })
        }
    },
    computed: {
        isNotAuthenticated: function() {
            if(!this.$store.getters.isAuthenticated) {
                return true
            }
            this.$router.push({name: 'home'})
        },
    }
}
</script>
<style scoped>
    
</style>