import MessagingService from '../../modules/messaging-service';

const state = {
  
}

const getters = {
  
}

const mutations = {
  
}

const actions = {
  
}

export default {
  state,
  getters,
  mutations,
  actions
}
