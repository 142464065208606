<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 lg:col-span-4">
        <WebsitesList :websites='websites' @selected="loadTags" :isLoading="isLoading" @search="loadSearchResult"/>
        <div class="intro-y flex flex-wrap sm:flex-row sm:flex-no-wrap items-center mt-3">
            <ul class="pagination">
                <li class="">
                    <a class="pagination__link pagination__link--active" href="#" @click.prevent="previousPage"><i data-feather="chevrons-left"></i> Previous</a>
                </li>
                <li class="">
                    <a class="pagination__link">{{ currentPage }} / {{ pageCount }}</a>
                </li>
                <li class="">
                    <a class="pagination__link pagination__link--active" href="#" @click.prevent="nextPage">Next <i data-feather="chevrons-right"></i></a>
                </li>
            </ul>
        </div>
    </div>
        <div class="col-span-12 lg:col-span-8">
        <WebsiteTags v-if="website" :website="website" />
      </div>

  </div>  
</template>

<script>
import WebsitesList from '@/components/websites/List'
import WebsiteTags from '@/components/tags/Tags'
import { RepositoryFactory } from '@/services/repositories/PublisherAPI/RepositoryFactory';
const WebsitesRepo = RepositoryFactory.get('websites');

export default {
    components: {
        WebsitesList,
        WebsiteTags,
    },
    data() {
        return {
            websites: [],
            website: {},
            tags: [],

            isLoading: false,
            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 10,
        }
    },
    created() {
        this.loadWebsites()
    },
    mounted() {
        this.$feather.replace()
    },
    methods: {
        loadWebsites: function() {
            this.isLoading = true
            WebsitesRepo.page(this.currentPage).then(result => {
                this.totalRecords = result.data.count
                this.websites = result.data.results
                if(this.websites[0]) 
                    this.loadTags(this.websites[0])

                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
            })
        },
        loadTags: function(website) {
            this.website = website
        },
        loadSearchResult(search_text) {
            this.isLoading = true
            WebsitesRepo.page(this.currentPage, this.resultsLimit, search_text).then(result => {
                this.totalRecords = result.data.count
                this.websites = result.data.results
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
            })
        },
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
                this.loadWebsites()
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
                this.loadWebsites()
            }
        },
    },
    computed: {
        pageCount: function() {
            return Math.ceil(this.totalRecords / this.resultsLimit)
        },
    },
}
</script>