<template>
<div class="mt-5">
    <div class="intro-y rounded-md flex items-center px-5 py-4 mb-2 bg-theme-18 text-theme-9" v-if="messages.length > 0" v-for="(message, key) in messages" :key="key">
        <fa-icon :icon="['fas', 'check']" class="w-6 h-6 mr-2" /> {{ message }}
    </div>
</div>
</template>
<script>
export default {
    name: 'DisplayMessages',
    props: {
      messages: Array,
    }
}
</script>

<style scoped>

</style>