<template>
  <div class="intro-y box mt-5">
    <div class="relative flex items-center p-5">
      <h3 class="font-medium text-base">{{ user.first_name }} {{ user.last_name }}</h3>
    </div>  
    <div class="p-5 border-t border-gray-200 dark:border-dark-5">
          <div class="font-normal text-base mb-5">
            <p> Pending Invoices </p>
            <h4 class="text-red-400 mb-2">
              {{ wallet.unpaid_invoices | withComma }}
              <span v-if="!isLoaded">0</span>
            </h4>
            <div class="border-t border-gray-200 dark:border-dark-5"></div>
          </div>
          
          <div class="font-normal text-base mb-5">
            <p> Due amount  </p>
            <h4 class="text-red-400 mb-3">
              ${{ wallet.unpaid_amount | withComma }}
              <span v-if="!isLoaded">0</span>
            </h4>
            <div class="border-t border-gray-200 dark:border-dark-5"></div>
          </div>
          <div class="font-normal text-base mb-5">
            <p> Paid Invoices </p>
            <h4 class=" mb-3">{{ wallet.paid_invoices | withComma }}</h4>
            <div class="border-t border-gray-200 dark:border-dark-5"></div>
          </div>
          <div class="font-normal text-base mb-5">
            <p> Total amount </p>
            <h4 class="mb-3">
              ${{ wallet.total | withComma }}
              <span v-if="!isLoaded">0</span>
            </h4>
          </div>
    </div>
  </div>
</template>
<script>
export default {
    props: {
      wallet: {
        required: true,
        type: Object
      },
      user: {
        required: true,
        type: Object
      },
      isLoaded: {
        required: true,
        type: Boolean
      }
    },
}
</script>
<style>

</style>