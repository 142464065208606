<template>
<div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 lg:col-span-3">
        <WalletsTotal v-if="account_totals" :wallet="account_totals" :user="user" :isLoaded="isLoaded" />
    </div>
    <div class="col-span-12 lg:col-span-9">
        <div class=" intro-y block sm:flex items-center justify-between box mt-5 p-5">
            <h3 class="text-lg font-medium truncate mr-5">List</h3>
            <div class="search hidden sm:block">
                <input type="search" name="table_search" class="search__input input placeholder-theme-13" placeholder="Search">
                <i data-feather="search" class="search__icon"></i>
            </div>
        </div>
        <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
            <!-- /.card-header -->
            <table class="table table-report sm:mt-2">
                <thead>
                    <tr>
                        <th class="whitespace-no-wrap">ID</th>
                        <th class="whitespace-no-wrap">Website</th>
                        <th class="text-center whitespace-no-wrap">Total Amount</th>
                        <th class="text-center whitespace-no-wrap">Status</th>
                        <th class="text-center whitespace-no-wrap">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="!isLoaded">
                        <td colspan="5">
                            <point-spinner class="text-center" />
                        </td>
                    </tr>
                    <tr v-if="isLoaded && wallets.length == 0">
                        <td colspan="5" class="text-center">
                            No available payments
                        </td>
                    </tr>
                    <tr class="intro-x" v-for="wallet in wallets" :key="wallet.id">
                        <td class="w-40">{{ wallet.id }}</td>
                        <td>{{ WebsiteName(wallet.website_id) }}</td>
                        <td class="text-center">${{ wallet.total | withComma }}</td>
                        <td class="text-center">
                            <span :class="{
                                'text-theme-9': wallet.status == 'active',
                                'text-theme-6': wallet.status == 'suspended'}"> {{ wallet.status | capitalize }} 
                            </span>
                        </td>
                        <td class="text-center">
                            <div class="btn-group btn-group-sm">
                                <router-link class="btn btn-primary" :to="{name: 'payment', params: {id: wallet.id}}"><i class="fa fa-edit"></i> View</router-link>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="intro-y flex flex-wrap sm:flex-row sm:flex-no-wrap items-center mt-3">
            <ul class="pagination">
                <li class="">
                    <a class="pagination__link pagination__link--active" href="#" @click.prevent="previousPage"><i data-feather="chevrons-left"></i> Previous</a>
                </li>
                <li class="">
                    <a class="pagination__link">{{ currentPage }} / {{ pageCount }}</a>
                </li>
                <li class="">
                    <a class="pagination__link pagination__link--active" href="#" @click.prevent="nextPage">Next <i data-feather="chevrons-right"></i></a>
                </li>
            </ul>
        </div>
        <!-- /.card -->
    </div>
</div>
</template>
<script>
import WalletsTotal from '@/components/wallets/side/WalletsTotal'
import { RepositoryFactory } from '@/services/repositories/PublisherAPI/RepositoryFactory' 
const WalletsRepo = RepositoryFactory.get('wallets')
const WebsitesRepo = RepositoryFactory.get('websites')
const AccountWalletsRepo = RepositoryFactory.get('account_wallets')

export default {
    components: {
        WalletsTotal,
    },
    data() {
        return {
            wallets: [],
            websites: [],

            account: 0,
            account_totals: {},
            user: {},

            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 10,
            isLoaded: true,
        }
    },
    mounted() {
        this.$feather.replace();
    },
    created() {
        this.loadWebsites()
        this.loadUserData()
        this.loadAccountWallets()
    },
    methods: {
        loadAccountWallets: function() {
            this.isLoaded = false
            AccountWalletsRepo.get(this.account).then(result => {
                this.totalRecords = result.data.count
                this.wallets = result.data.results
                this.account_totals = result.data.total

                this.isLoaded = true
            }).catch(err => {
                this.isLoaded = true
            })
        },
        loadWebsites: function() {
            WebsitesRepo.all().then(result => {
                this.websites = result.data.results
            })
        },
        loadUserData: function() {
            this.user = this.$store.getters.user
            this.account = this.user.id
        },
        WebsiteName: function(website_id) {
            var website = this.websites.filter(element => { return element.id == website_id })[0]
            if(website)
                return website.name
        },
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
            }
        },
  },
  computed: {
    pageCount: function() {
            return Math.ceil(this.totalRecords / this.resultsLimit)
        },
    },
    watch: {
    }

}
</script>