import Repository from './Repository';

const resource = "/auth";
export default {
    login(data) {
        return Repository.post(`${resource}/token/`, data);
    },
    logout(data=null) {
        return Repository.post(`${resource}/logout/`, data);
    },
    verify_token(data) {
        return Repository.post(`${resource}/token/verify/`, data);
    },
    verify(data) {
        return Repository.post(`${resource}/token/verify/`, data);
    },
    signup(data) {
        return Repository.post(`${resource}/registration/`, data);
    },
    forgot(data) {
        return Repository.post(`${resource}/forgot/`, data);
    },
    reset(data) {
        return Repository.put(`${resource}/reset/`, data);
    },
}