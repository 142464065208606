import Repository from './Repository';

const resource = "/ads";
export default {
    all() {
        return Repository.get(`${resource}/ads/`);
    },
    get() {
        return Repository.get(`${resource}/user/`);
    },
    text() {
        // return Repository.get(`${resource}/user/txt/`);
        return Repository.get(`${resource}/txt/`);
    },
}