function statisticsToGraphData(data, l14days=false, yesterday=false, reverse=false) {
    /**
     * website:
     * name:
     * id:
     * url:
     * 30: date[1,2,3,4,5,6], cpm[1,2,3,4,5,6], impressions[1,2,3,4,5,6], revenue[1,2,3,4,5,6], loop the data get value
     * 14: date[1,2,3,4,5,6], cpm[1,2,3,4,5,6], impressions[1,2,3,4,5,6], revenue[1,2,3,4,5,6], loop the data get value
     * 01: date[1,2,3,4,5,6], cpm[1,2,3,4,5,6], impressions[1,2,3,4,5,6], revenue[1,2,3,4,5,6], loop the data get value
     */
    var last30days = {}

    var date = []
    var cpm = []
    var impressions = []
    var revenue = []
    var viewable_rate = []
    var counter = 0

    data.forEach(function (statistic, key) {
        date.push(statistic.date);
        Object.entries(statistic).forEach(function (stats) {
            if(stats[0] === 'cpm') {
                cpm.push(stats[1]);
            }
            if(stats[0] === 'impressions') {
                impressions.push(stats[1]);
            }
            if(stats[0] === 'revenue') {
                revenue.push(stats[1]);
            }
            if(stats[0] === 'viewable_rate') {
                viewable_rate.push(stats[1]);
            }
        });
        counter++;
    });

    if(reverse) {
        date = date.reverse()
        cpm = cpm.reverse()
        impressions = impressions.reverse()
        revenue = revenue.reverse()
        viewable_rate = viewable_rate.reverse()
    }

    last30days = {
        "date": date,
        "cpm": cpm,
        "impressions": impressions,
        "revenue": revenue
    };

    if(l14days) {
        var data14days = {}
        var cutRecords = 14
        var length = date.length - cutRecords

        data14days = {
            "date": date.slice(length),
            "cpm": cpm.slice(length),
            "impressions": impressions.slice(length),
            "revenue": revenue.slice(length),
        }

        if(length < 0) {
            data14days = {
                "date": date,
                "cpm": cpm,
                "impressions": impressions,
                "revenue": revenue,
            }
        }
        return data14days
    } 
    
    if(yesterday) {
        var before_yesterday = {
            "date": date[date.length -3],
            "cpm": cpm[cpm.length -3],
            "impressions": impressions[impressions.length -3],
            "revenue": revenue[revenue.length -3],
            "viewable_rate": viewable_rate[viewable_rate.length -3],
        }
        yesterday = {
            "date": date[date.length -2],
            "cpm": cpm[cpm.length -2],
            "impressions": impressions[impressions.length -2],
            "revenue": revenue[revenue.length -2],
            "viewable_rate": viewable_rate[viewable_rate.length -2],
        }
        yesterday = {
            yesterday,
            before_yesterday
        }
        return yesterday
    }
    return last30days
}

export default statisticsToGraphData