import MessagesRepo from './MessagesRepo';
import NotificationsRepo from './NotificationsRepo';
import DevicesRepo from './DevicesRepo';

const repositories = {
    messages: MessagesRepo,
    notifications: NotificationsRepo,
    devices: DevicesRepo,
}

export const RepositoryFactory = {
    get: name => repositories[name],
};