import Repository from './Repository';

const resource = "/devices";
export default {
    add(data) {
        return Repository.post(`${resource}/`, data);
    },
    update(id, data) {
        return Repository.put(`${resource}/${id}/`, data);
    },
}