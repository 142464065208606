<template>
    <!-- BEGIN: Impersonating Floating info bubble -->
    <div v-if="isImpersonated" class="dark-mode-switcher cursor-pointer shadow-md fixed bottom-0 right-0 box border rounded-full p-5 w-50 h-16 flex items-center justify-center z-50 mb-10 mr-10">
        <div class="flex">
            <div class="w-10 h-10 image-fit">
                <img alt="Impersonating user image" class="tooltip rounded-full" :src="impersonating_user.profile_image || '/assets/images/profile-7.jpg'">
            </div>
        </div>
        <div class="p-1 dark:border-gray-700">
            <div class="text-xs text-black">{{ impersonating_user.full_name }}</div>
            <div class="text-xs text-gray-500">{{ impersonating_user.role | capitalize }}</div>
        </div>
    </div>
    <!-- END: Impersonating Floating info bubble -->
</template>>
<script>
export default {
  data: function() {
    return{
      messages: []
    }
  },
  props: {
  },
  components: {
  },
  methods: {
  },
  mounted() {
  },
  computed: {
    impersonating_user () {
      return this.$store.getters.impersonating_user
    },
    isImpersonated () {
      return this.$store.getters.isImpersonated
    }
  }
}
</script>

<style scoped>

</style>