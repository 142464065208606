<template>
    <div>
        <div class="grid grid-cols-12 gap-6 mt-8">
            <div class="col-span-12 lg:col-span-3 xxl:col-span-2">
                <h2 class="intro-y text-lg font-medium mr-auto mt-2">
                    Notifications
                </h2>
                <!-- BEGIN: Inbox Menu -->
                <!-- END: Inbox Menu -->
            </div>
            <div class="col-span-12 ">
                <!-- BEGIN: Inbox Filter -->
                <div class="intro-y flex flex-col-reverse sm:flex-row items-center">
                    <div class="w-full sm:w-auto relative mr-auto mt-3 sm:mt-0">
                        <i class="w-4 h-4 absolute my-auto inset-y-0 ml-3 left-0 z-10 text-gray-700" data-feather="search"></i> 
                        <input type="text" class="input w-full sm:w-64 box px-10 text-gray-700 placeholder-theme-13" placeholder="Search mail">
                    </div>
                </div>
                <!-- END: Inbox Filter -->
                <!-- BEGIN: Inbox Content -->
                <div class="intro-y inbox box mt-5">
                    <div class="p-5 flex flex-col-reverse sm:flex-row text-gray-600 border-b border-gray-200">
                        <div class="flex items-center mt-3 sm:mt-0 border-t sm:border-0 border-gray-200 pt-5 sm:pt-0 mt-5 sm:mt-0 -mx-5 sm:mx-0 px-5 sm:px-0">
                            <input class="input border border-gray-500" type="checkbox"> 
                        </div> 
                    </div>
                    <div class="overflow-x-auto sm:overflow-x-visible">
                        <div class="intro-y" v-for="message in messages" :key="message.id">
                            <div class="inbox__item inbox__item--active inline-block sm:block text-gray-700 bg-gray-100 border-b border-gray-200">
                                <div class="flex px-5 py-3">
                                    <div class="w-56 flex-none flex items-center mr-10">
                                        <input class="input flex-none border border-gray-500" type="checkbox" >
                                        <a href="javascript:;" class="w-5 h-5 flex-none ml-4 flex items-center justify-center text-gray-500"> <i class="w-4 h-4" data-feather="star"></i> </a>
                                        <a href="javascript:;" class="w-5 h-5 flex-none ml-2 flex items-center justify-center text-gray-500"> <i class="w-4 h-4" data-feather="bookmark"></i> </a>
                                        <div class="w-6 h-6 flex-none image-fit relative ml-5">
                                            <img alt="" class="rounded-full" src="public/assets/img/Profile_avatar_placeholder.png">
                                        </div>
                                        <div class="inbox__item--sender truncate ml-3"></div>
                                    </div>
                                    <router-link :to="{name: 'notification', params: { id: message.id }}" class="w-64 sm:w-auto truncate" data-toggle="modal" data-target="#header-footer-modal-preview">
                                        <span class="inbox__item--highlight"></span> {{ message.content }}
                                    </router-link>
                                    <div class="inbox__item--time whitespace-no-wrap ml-auto pl-10"> {{ message.created_at }} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>        
                <!-- END: Inbox Content -->
                 <!--pagination-->
                <div class="intro-y flex flex-wrap sm:flex-row sm:flex-no-wrap items-center mt-3">
                    <ul class="pagination">
                        <li>
                            <a class="pagination__link pagination__link--active" href="#" @click.prevent="previousPage">Previous</a>
                        </li>
                        <li>
                            <a class="pagination__link">{{ currentPage || 1 }} / {{ pageCount || 1}}</a>
                        </li>
                        <li>
                            <a class="pagination__link pagination__link--active" href="#" @click.prevent="nextPage">Next</a>
                        </li>
                    </ul>
                </div>
                 <!--end of pagination-->
            </div>
            <router-view />
        </div>

        <!--Modal view-->
        <div class="modal" id="header-footer-modal-preview" v-if="message">
            <div class="modal__content mt-32">
                <div class="chat__chat-list  overflow-y-auto scrollbar-hidden pr-1 pt-1 mt-4">
                    <div class="intro-x cursor-pointer box relative flex items-center p-5 ">
                        
                        <div class="ml-2 overflow-hidden">
                            <div class="flex items-center">
                                <div class="text-xs text-gray-500 ml-auto">{{ message.created_at }}</div>
                            </div>
                            <div class="w-full text-gray-600">{{ message.content }}</div>
                        </div>
                    </div>
                </div>    
            </div>
        </div>
        <!--end of modal view-->
    </div>
</template>
<script>

export default {
    data() {
        return {
            messages: [
                {
                    id: 0,
                    content: 'No available notifications',
                    created_at: '01:50 PM'
                }
            ],
            message: {},
        }
    },
    mounted: function() {
        this.$feather.replace()
    },
    created: function() {
        this.$feather.replace()
    },
    methods: {
        previousPage() {},
        nextPage() {}
    },
    watch: {
        '$route.params.id': function(id) {
            if( id != null && id != '' )
                this.message = this.messages.filter(message => message.id == id)[0]
        }
    }
}
</script>
<style scoped >

</style>