<template>
<div class="grid grid-cols-12 gap-6">
    <!-- BEGIN: Menu -->
    <UserCard :user="user" :profile="profile" />
    <!-- END: Menu -->
    <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
      <router-view></router-view>
    </div>
</div>
</template>

<script>
import UserCard from '@/components/account/profile/UserCard'

import { RepositoryFactory } from '@/services/repositories/AuthAPI/RepositoryFactory';
const ProfileRepo = RepositoryFactory.get('profile');

export default {
    name: 'Profile',
    components: {
      UserCard,
    },
    data() {
      return {
        profile: null,
      }
    },
    created() {
      this.getProfileInfo()
    },
    mounted() {
      this.$feather.replace()
    },
    methods: {
      getProfileInfo: async function() {
        const { data } = await ProfileRepo.get(this.user.id)
        this.profile = data
      },
    },
    computed: {
      user: function() {
        return this.$store.getters.user
      },
    }
}
</script>

<style scoped>

</style>