<template>
    <div>
        <h2 class="intro-x text-lg font-medium truncate mr-5 mb-8">YESTERDAY OVERALL</h2>
        <div class="overflow-hidden rounded-sm pt-1 ">
            <div class="intro-y rounded-md shadow-lg bg-white flex flex-col mb-4 pl-6 pr-6 YO-bg-dark">
                <div class="flex items-baseline justify-between p-1 mr-40">
                    <div class="text-base font-medium tracking-tighter mb-1 pt-2">Viewable Rate</div>
                    <div class="flex items-baseline justify-between">
                        <fa-icon v-if="statistics.viewable_rate_diff > 0" :icon="['fas',  'chevron-up']" class="text-theme-9 mr-1 " />
                        <fa-icon v-if="statistics.viewable_rate_diff < 0" :icon="['fas',  'chevron-down']" class="text-theme-6 mr-1 " />
                        <span v-if="statistics.viewable_rate_diff == 0" class="text-theme-8 mr-1 ">0%</span>
                        <p v-if="statistics.viewable_rate_diff" class=" text-xs ">{{ statistics.viewable_rate_diff }}%</p>
                    </div>
                </div>
                <div v-if="statistics.viewable_rate_diff" class="w-full flex items-baseline justify-between p-2" >
                    <Progress :percentage="statistics.viewable_rate_diff" :color="statViewRateColor" class="mr-5 mb-2" />
                    <radial-progress-bar innerStrokeColor="#e2e8f0" :startColor="statViewRateProgressColor" :stopColor="statViewRateProgressColor" :diameter="120" 
                        :completed-steps="parseInt(statistics.viewable_rate)" :total-steps="100">
                        <p>{{ statistics.viewable_rate }}%</p>
                    </radial-progress-bar>
                </div>
            </div>
            <div class="intro-y rounded-md shadow-lg bg-white flex mb-3 pl-6 pr-6 flex-col YO-bg-dark">
                <div class="flex items-baseline justify-between p-1 ">
                    <div class="text-base font-medium tracking-tighter mb-1 pt-2">Impressions</div>
                    <div class="flex items-baseline justify-between">
                        <div class="text-lg font-bold leading-8 mr-3 ">{{ statistics.impressions | withComma }}</div>
                        <fa-icon v-if="statistics.impressions_diff > 0" :icon="['fas',  'chevron-up']" class="text-theme-9 mr-1 " />
                        <fa-icon v-if="statistics.impressions_diff < 0" :icon="['fas',  'chevron-down']" class="text-theme-6 mr-1 " />
                        <span v-if="statistics.impressions_diff == 0" class="text-theme-8 mr-1 ">0%</span>
                        <p v-if="statistics.impressions_diff" class=" text-xs ">{{ statistics.impressions_diff }}%</p>
                    </div>
                </div>
                <div class="w-full flex flex-wrap p-2">
                    <Progress :color="statImpColor" :percentage="statistics.impressions_diff" class="mb-2" />
                </div>
            </div>
            <div class="intro-y rounded-md shadow-lg bg-white flex mb-3 pl-6 pr-6 flex-col YO-bg-dark">
                <div class="flex items-baseline justify-between p-1 ">
                    <div class="text-base font-medium tracking-tighter mb-1 pt-2">CPM</div>
                    <div class="flex items-baseline justify-between">
                        <div class="text-lg font-bold leading-8 mr-3 ">${{ statistics.cpm | withComma }}</div>
                        <fa-icon v-if="statistics.cpm_diff > 0" :icon="['fas',  'chevron-up']" class="text-theme-9 mr-1 " />
                        <fa-icon v-if="statistics.cpm_diff < 0" :icon="['fas',  'chevron-down']" class="text-theme-6 mr-1 " />
                        <span v-if="statistics.cpm_diff == 0" class="text-theme-5 mr-1 ">0%</span>
                        <p v-if="statistics.cpm_diff" class=" text-xs ">{{ statistics.cpm_diff }}%</p>
                    </div>
                </div>
                <div  class="w-full flex flex-wrap p-2">
                    <Progress :color="statCpmColor" :percentage="statistics.cpm_diff" class="mb-2" />
                </div>
            </div>
            <div class="intro-y rounded-md shadow-lg bg-white flex mb-4 pl-6 pr-6 flex-col YO-bg-dark">
                <div class="flex items-baseline justify-between p-1 ">
                    <div class="text-base font-medium tracking-tighter mb-1 pt-2">Revenue</div>
                    <div class="flex items-baseline justify-between">
                        <div class="text-lg font-bold leading-8 mr-3 ">${{ statistics.revenue | withComma}}</div>
                        <fa-icon v-if="statistics.revenue_diff > 0" :icon="['fas',  'chevron-up']" class="text-theme-9 mr-1 " />
                        <fa-icon v-if="statistics.revenue_diff < 0" :icon="['fas',  'chevron-down']" class="text-theme-6 mr-1 " />
                        <span v-if="statistics.revenue_diff == 0" class="text-theme-8 mr-1 ">0%</span>
                        <p v-if="statistics.revenue_diff" class=" text-xs ">{{ statistics.revenue_diff }}%</p>
                    </div>
                </div>
                <div class="w-full flex flex-wrap p-2">
                    <Progress :color="statRevColor" :percentage="statistics.revenue_diff" class="mb-2" />
                </div>
            </div>
        </div>    
    </div>
</template>
<script>
import Progress from "../core/util/Progress";
import RadialProgressBar from 'vue-radial-progress'

export default {
    name: "Yesterday",
    components:{
        Progress,
        RadialProgressBar
    },
    props: {
        statistics: {
            required: false,
            type: Object | Array
        },
        isLoading: {
            required: true,
            type: Boolean
        },
    },
    data(){
      return{
        fill : { gradient: ["red", "green", "blue"] },
        statViewRateColor : this.statistics.viewable_rate_diff > 0 ? "green" : "red" ,
        statViewRateProgressColor : this.statistics.viewable_rate_diff > 0 ? "#48bb78" : "#F56666" ,
        statImpColor : this.statistics.impressions_diff > 0 ? "green" : "red" ,
        statCpmColor : this.statistics.cpm_diff > 0 ? "green" : "red" ,
        statRevColor : this.statistics.revenue_diff > 0 ? "green" : "red"
        
      }  
    },
    methods:{
      progress(event, progress, stepValue){
      },
      progress_end(event){
      }
    },
    created() {
    },
    watch: {
        data: function() {
        },
        statistics: function() {
            this.statViewRateColor = this.statistics.viewable_rate_diff > 0 ? "green" : "red" ,
            this.statViewRateProgressColor = this.statistics.viewable_rate_diff > 0 ? "#48bb78" : "#F56666" ,
            this.statImpColor = this.statistics.impressions_diff > 0 ? "green" : "red" ,
            this.statCpmColor = this.statistics.cpm_diff > 0 ? "green" : "red" ,
            this.statRevColor = this.statistics.revenue_diff > 0 ? "green" : "red"
        }
    },
    computed: {
    }
}
</script>
<style scoped>
</style>