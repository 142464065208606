<template>
  <div class="intro-y box mt-5">
    <div class="relative flex items-center p-5">
      <h3 class="font-medium text-base"><fa-icon :icon="['fa', 'globe']"></fa-icon> {{ WebsiteName(wallet.website_id) }}</h3>
    </div>
      <div class="relative flex items-center p-5">
        <p class="font-medium text-base">#{{ wallet.id }}</p>
      </div>
      <!-- /.card-tools -->
    <!-- /.card-header -->
    <div class="p-5 border-t border-gray-200 dark:border-dark-5">
      <div class="">
        <div class="font-normal text-base mb-5">
          <p> Pending Invoices </p>
          <h4 class="text-red-400 mb-2">
            {{ wallet.unpaid_invoices | withComma }}
          </h4>
            <div class="border-t border-gray-200 dark:border-dark-5"></div>
        </div>
        
        <div class="font-normal text-base mb-5">
          <p> Due amount  </p>
          <h4 class="text-red-400 mb-2">
            ${{ wallet.unpaid_amount | withComma }}
          </h4>
            <div class="border-t border-gray-200 dark:border-dark-5"></div>
        </div>
        <div class="font-normal text-base mb-5">
          <p> Paid Invoices </p>
          <h4 class="text-red-400 mb-2">
            {{ wallet.paid_invoices | withComma }}
          </h4>
            <div class="border-t border-gray-200 dark:border-dark-5"></div>
        </div>
        <div class="font-normal text-base mb-5">
          <p> Total amount </p>
          <h4 class="text-red-400 mb-2">
            ${{ wallet.total | withComma }}
          </h4>
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.card-body -->
  </div>
  <!-- /.card -->
</template>
<script>
export default {
    props: {
      website: {
        required: true,
        type: Object
      },
      wallet: {
        required: true,
        type: Object
      }
    },
    mounted(){
      
    },
    methods:{
      WebsiteName: function(website_id) {
            let website = this.website.results.filter(element => { return element.id == website_id })[0]
            if(website)
                return website.name
        },
    },
}
</script>
<style>

</style>